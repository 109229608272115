<template>
  <ion-page>
    <ion-content style="--background: var(--ion-color-light)">
      <ion-header>
        <ion-toolbar>
          <ion-title>Add</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-card class="main-card">
        <ion-card-header>
          <router-link :to="{ path: `/member/medicalhistory/` }" class="logo"><img src="assets/icon/back.svg" width="26" style="float: left"
          /></router-link>

          <div class="header" style="margin-top: 47px">
            <h5>
              Add new hospital 
              <br />
              admission
            </h5>
          </div>
        </ion-card-header>

        <ion-card-content>
          <form ref="form" @submit.prevent="submit">
            <div class="date-picker-div">
                <label class="date-label" for="date">Date of Admission</label>
                <datepicker id="date" v-model="form.date" inputFormat="yyyy/MM/dd" :upperLimit="today" typeable="true" required class="date" autocomplete="off"/>
              </div>

            <span class="p-float-label">
              <InputText id="reason" type="text" v-model="form.reason" required />
              <label for="reason">Reason for Admission</label>
            </span>

            <span class="p-float-label">
              <InputText id="length" type="text" v-model="form.length" required />
              <label for="length">Length of Admission</label>
            </span>

            <ion-button size="small" shape="round" type="submit" style="margin-top: 35px;">Add</ion-button>
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
} from "@ionic/vue";
import Datepicker from 'vue3-datepicker'

export default {
  name: "Add Hospital Admissions",
  components: {
    IonContent,
    IonCardHeader,
    IonCardContent,
    IonCard,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonPage,
    Datepicker
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      form: {
        date: new Date(),
        reason: null,
        length: null,
        user_id: null,
        category: 'Hospital Admission',
      },
      today: new Date(),
    };
  },
  ionViewDidEnter() {
    this.form.user_id = this.user.id;
  },
  methods: {
    submit: function () {
      this.$store.dispatch("AddMedicalHistory", this.form);
    },
  }
};
</script>

<style scoped>
h5 {
  font-weight: 700;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  color: var(--ion-color-primary);
}

.main-card {
  margin-top: 12px;
}

ion-button {
  float: right;
}

.p-dropdown {
  font-family: Poppins;
  height: 43.5px;
  width: 100%;
  color: #777777;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  border: 1px solid #777777;
  margin-bottom: 27px;
  text-align: left;
}

</style>